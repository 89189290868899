import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Box } from "@rebass/grid/emotion"

import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"

import UserContext from "../../../../context/UserContext"
import AccountSVG from "../../../../assets/icons/wild/account-icon-wild.svg"
import { Flex } from "reflexbox"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

const AccountLogo = styled.div``

const AccountFlex = styled(Flex)`
  flex-direction: row;
  margin: auto auto 0 auto;
  border-bottom: 1px solid ${theme.wild.color.master.primary.default};
  padding: 0 5px 0 5px;
  > * {
    margin-top: auto;
    margin-bottom: auto;
  }
  svg {
    path {
      fill: #008078;
    }
  }
`

const AccountBox = styled(Box)`
  ${queries.medium`
    padding-right: 0;
  `}
  svg {
    margin: auto 5px auto 0;
    width: 13.13px;
    height: 12.5px;
    ${queries.medium`
      stroke: ${theme.wild.color.master.primary.default};
      width: 13.13px;
      height: 12.5px;
    `}
  }
  &.simple-account {
    padding: 7px;
  }
`

const AccountIcon = (props) => {
  const { isSimple } = props

  const handleAccountClick = (destination) => {
    segmentEvent("Navigation Clicked", {
      destination,
      module_location: "navigation",
      text: "My Account",
      type: "link",
    })
  }

  return (
    <UserContext.Consumer>
      {(userState) => (
        <>
          <AccountBox className={`${!isSimple ? null : "simple-account"}`}>
            {isSimple ? (
              <>
                {userState.isLoggedIn ? (
                  <AccountLogo>
                    <Link
                      to={"/account/"}
                      onClick={() => handleAccountClick("/account/")}
                    >
                      <AccountFlex>
                        <AccountSVG />
                        <TextElement
                          element="p"
                          text={`My Account`}
                          className="body--large"
                          styles={{
                            color: theme.wild.color.master.primary.default,
                          }}
                        />
                      </AccountFlex>
                    </Link>
                  </AccountLogo>
                ) : (
                  <AccountLogo>
                    <Link
                      to={"/customer/account/login/"}
                      onClick={() =>
                        handleAccountClick("/customer/account/login/")
                      }
                    >
                      <AccountSVG />
                    </Link>
                  </AccountLogo>
                )}
              </>
            ) : (
              <>
                {userState.isLoggedIn ? (
                  <AccountLogo>
                    <Link
                      to={"/account/"}
                      onClick={() => handleAccountClick("/account/")}
                    >
                      <AccountFlex>
                        <AccountSVG />
                        <TextElement
                          element="p"
                          text={`My Account`}
                          className="body--large"
                          styles={{
                            color: theme.wild.color.master.primary.default,
                          }}
                        />
                      </AccountFlex>
                    </Link>
                  </AccountLogo>
                ) : (
                  <AccountLogo>
                    <Link
                      to={"/customer/account/login/"}
                      onClick={() =>
                        handleAccountClick("/customer/account/login/")
                      }
                    >
                      <AccountFlex>
                        <AccountSVG />
                        <TextElement
                          element="p"
                          text={`My Account`}
                          className="body--large"
                          styles={{ color: theme.wild.color.master.base.black }}
                        />
                      </AccountFlex>
                    </Link>
                  </AccountLogo>
                )}
              </>
            )}
          </AccountBox>
        </>
      )}
    </UserContext.Consumer>
  )
}

export default AccountIcon
