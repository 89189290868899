import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"
import twitter from "../images/twitter.svg"
import blog from "../images/blog.svg"
const mageURL = process.env.GATSBY_MAGENTO_URL || ""

export const data = {
  copy: {
    title:
      "© " +
      new Date().getFullYear() +
      " Nutraceutical Wellness Inc. All Rights Reserved.  ",
    text: "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
  },
  keep: {
    title: "BETTER HAIR IS JUST THE BEGINNING. LET’S KEEP GROWING.",
    text: "Our own experience with thinning hair is what sparked our passion for hair wellness innovation. We strive to advance the field of hair science so you can be free to grow into the best version of yourself — your hair, your health, your happiness, and your power to help others grow too.",
  },
  patent: {
    title: "OUR PATENT",
    text: "Synergen Complex<sup>&reg;</sup> is a registered trademark of Nutraceutical Wellness Inc.<br/>U.S. Patent Nos. 11,213,478, 10,709,659 and 10,688,037.",
  },
  email: {
    title: "Stay in the thick of it with our newsletter.",
    titleDesktop: "Stay in the thick of it.",
    text: "Email address",
    disclaimer: "Get offers and updates sent directly to your inbox.",
  },
  mobile: [
    {
      title: "FAQ",
      link: "/faq/",
    },
    {
      title: "CONTACT",
      link: "/contact-us/",
    },
    {
      title: "PRESS",
      link: "/press/",
    },
  ],
  wellness: [
    {
      title: "SHOP PRODUCTS",
      link: "/products/",
    },
    {
      title: "THE SCIENCE",
      link: "/science/",
    },
    {
      title: "INGREDIENTS",
      link: "/ingredients/",
    },
    {
      title: "RESULTS",
      link: "/results/",
    },
    {
      title: "FIND A PROVIDER",
      link: "/partners/",
    },
    {
      title: "blog",
      link: mageURL + "blog/",
    },
    {
      title: "FAQ",
      link: "/faq/",
    },
  ],
  company: [
    {
      title: "CONTACT",
      link: "/contact-us/",
    },
    {
      title: "PRESS",
      link: "/press/",
    },
    {
      title: "CAREERS",
      link: "https://sites.google.com/nutrafol.com/careers/careers",
    },
    {
      title: "OUR STORY",
      link: "/story/",
    },
    {
      title: "OUR SERVICE",
      link: "/service/",
    },
    {
      title: "BECOME A PARTNER",
      link: "/professionals/",
    },
    {
      title: "SOCIAL PURPOSE",
      link: "/social-purpose/",
    },
  ],
  policy: [
    {
      title: "TERMS OF USE",
      link: "/terms-of-use/",
    },
    {
      title: "PRIVACY POLICY",
      link: "/privacy-policy/",
    },
  ],
  sitemap: {
    text: "Sitemap",
    link: "/sitemap-0.xml",
  },
  social: [
    {
      title: "blog",
      img: blog,
      link: mageURL + "blog/",
    },
    {
      title: "facebook",
      img: facebook,
      link: "https://www.facebook.com/nutrafol/",
    },
    {
      title: "instagram",
      img: instagram,
      link: "https://www.instagram.com/nutrafol/",
    },
    {
      title: "twitter",
      img: twitter,
      link: "https://twitter.com/nutrafol/",
    },
  ],
}

export const altData = {
  copy: {
    title:
      "© " +
      new Date().getFullYear() +
      " Nutraceutical Wellness Inc. <br>All Rights Reserved.  ",
    text: "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
  },
  social: [
    {
      title: "facebook",
      img: facebook,
      link: "https://www.facebook.com/nutrafol/",
    },
    {
      title: "instagram",
      img: instagram,
      link: "https://www.instagram.com/nutrafol/",
    },
    {
      title: "twitter",
      img: twitter,
      link: "https://twitter.com/nutrafol/",
    },
    {
      title: "blog",
      img: blog,
      link: mageURL + "blog/",
    },
  ],
}
