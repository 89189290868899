import React from "react"
import styled from "styled-components"
import { Flex, Box } from "@rebass/grid/emotion"

import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import Logo from "../../../../assets/icons/wild/logo-icon.svg"

import AdChoicesLink from "../../global/adchoiceslink/adchoiceslink"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

const AdChoicesWrapper = styled(Box)`
  span.textelement.body--regular {
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
  }
`

const text =
  "© " +
  new Date().getFullYear() +
  "  Nutraceutical Wellness Inc. All Rights Reserved."

const FooterReallySimpleWild = () => {
  return (
    <footer data-mousedown>
      <Flex
        flexWrap="wrap"
        style={{ backgroundColor: theme.wild.color.master.base.secondary }}
      >
        <Box width={1}>
          <Flex
            flexWrap="wrap"
            className="container"
            alignItems="center"
            pt={[40, 40, 90]}
            pb={[100, 100, 90]}
          >
            <Box width={[65, 65, 80]} pr={[25, 25, 40]}>
              <Logo />
            </Box>
            <Box flex={1}>
              <TextElement element="p" text={text} className="caption mb-2" />
              <TextElement
                element="p"
                text="These statements have not been evaluated by the Food and Drug Administration. <br/>This product is not intended to diagnose, treat, cure or prevent any disease."
                className="caption mb-2"
                styles={{ maxWidth: "370px" }}
              />
              <a
                href={"/sitemap-0.xml"}
                target="_blank"
                rel="noopener noreferrer mb-2"
                aria-label={`Sitemap (Opens in new tab)`}
              >
                <TextElement
                  element="p"
                  className="underline body--small"
                  text={"Sitemap"}
                  styles={{ color: theme.wild.color.master.base.black }}
                />
              </a>
              <AdChoicesWrapper mt={8}>
                <AdChoicesLink />
              </AdChoicesWrapper>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </footer>
  )
}

export default FooterReallySimpleWild
