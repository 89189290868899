import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

const getIsMobile = () => typeof window !== "undefined" && window.innerWidth <= 767

const useIsMobileResize = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile())

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile())
    }

    window.addEventListener("resize", debounce(onResize, 100))

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return isMobile
}

export default useIsMobileResize