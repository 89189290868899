import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"

import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { handleNavigationSegmentEvent } from "./utils/segmentEvents"

import OpenMenuIcon from "../../../../assets/icons/wild/open-menu-icon.svg"
import CloseMenuIcon from "../../../../assets/icons/wild/close-menu-icon.svg"
import HeaderCartIcon from "./headercarticon"
import UserContext from "../../../../context/UserContext"
import AccountSVG from "../../../../assets/icons/wild/account-icon-wild.svg"
import AccountSVGGreen from "../../../../assets/icons/wild/account-icon-wild-green.svg"
import SearchIcon from "../../../../assets/icons/header-search.svg"

import { SearchContext } from "../../../search/searchcontext"
import { SearchIconContainer } from "../../../search/search.styles"

const tabletFold = 1024

const HamburgerOpenCloseContainer = styled.div`
  @media (min-width: ${tabletFold}px) {
    display: none;
  }
`

const HamburgerOpenCloseIcon = styled.button`
  padding: 13px 11px;
  height: 52px;
  .close-nav {
    margin-left: 2px;
    margin-right: 2px;
  }
`

const QuizButtonBox = styled(Box)`
  .tablet-button {
    display: none;
    ${queries.medium`
      display: block;
      font-size: 14px;
    `}
    @media (min-width: ${tabletFold}px) {
      display: none;
    }
  }
  .desktop-button {
    display: none;
    @media (min-width: ${tabletFold}px) {
      display: block;
    }
  }
`
const CartBox = styled(Box)`
  margin-right: 5px;
  @media (min-width: 365px) {
    margin-right: 10px;
  }
  @media (min-width: 768px) {
    margin-right: 0px;
  }
`

const BurgerBox = styled(Box)`
  width: 40px;
  @media (min-width: 350px) {
    width: 40px;
  }
  @media (min-width: ${tabletFold}px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 800px) {
    margin-left: -4px;
  }
  @media (max-width: 767px) {
    button {
      height: 52px;
      svg.close-nav {
        width: 16px;
        height: 16px;
      }
      svg.open-nav {
        width: 20px;
        height: 16px;
      }
    }
  }
`
const AccountBox = styled(Box)`
  display: none;
  @media (min-width: ${tabletFold}px) {
    display: block;
  }
  svg {
    width: 18px;
    height: 17px;
    ${queries.medium`
      width: 20px;
      height: 19px;
    `}
  }
  &.simple-account {
    padding: 7px;
  }
  ${queries.medium`
    padding-right: 0;
  `}
`

const AccountFlex = styled(Flex)`
  line-height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
  @media (min-width: 1024px) {
    transform: translate(0, 3px);
  }
`

const HeaderIcons = (props) => {
  const {
    navIsOpen,
    showCart,
    isProductPage,
    isAccountPage,
    isQuizLp,
    toggleMobileNav,
    isSimple,
    classNameBurger = `hide-md-up`,
    isIconVisibleOnRightSideOnly = false,
    location,
  } = props

  const mageURL = process.env.GATSBY_MAGENTO_URL
  const ctaText = "Take the Quiz"
  const ctaTextAcct = `My Account`
  const dest = !isQuizLp ? "quiz/" : "quiz/?skip=true"

  const { toggleSearchDropdown, closeSearchDropdown } =
    useContext(SearchContext)

  const onClick = () => {
    segmentEvent("Marketing CTA Clicked", {
      destination: dest,
      module_location: "navigation",
      text: ctaText,
      type: "button",
    })
    window.location = mageURL + dest
  }

  const onClickAcct = () => {
    navigate("/account/")
  }

  const children = (
    <AccountFlex>
      <Box mr={["4px"]}>
        <AccountSVGGreen />
      </Box>
      <Box>{ctaTextAcct}</Box>
    </AccountFlex>
  )

  return (
    <UserContext.Consumer>
      {(userState) => (
        <>
          {!isSimple && (
            <QuizButtonBox mr={[0, 0, 15, 30]}>
              {userState.isLoggedIn ? (
                isAccountPage ? null : (
                  <ButtonWild
                    textLink
                    children={children}
                    className="tablet-button"
                    onClick={onClickAcct}
                  />
                )
              ) : (
                <ButtonWild
                  textLink
                  arrowRight
                  ctaLink={mageURL + dest}
                  label={ctaText}
                  className="tablet-button"
                  onClick={onClick}
                />
              )}
              {userState.isLoggedIn ? (
                isAccountPage ? null : (
                  <ButtonWild
                    children={children}
                    className="desktop-button"
                    onClick={onClickAcct}
                  />
                )
              ) : (
                <>
                  {isProductPage ? (
                    <ButtonWild
                      label={ctaText}
                      ctaLink={mageURL + dest}
                      className="desktop-button"
                      onClick={onClick}
                    />
                  ) : (
                    <ButtonWild
                      primary
                      label={ctaText}
                      ctaLink={mageURL + dest}
                      className="desktop-button"
                      onClick={onClick}
                    />
                  )}
                </>
              )}
            </QuizButtonBox>
          )}
          {isIconVisibleOnRightSideOnly ? (
            <SearchIconContainer>
              <button
                onClick={() => {
                  if (navIsOpen) {
                    toggleMobileNav(false)
                  }
                  toggleSearchDropdown()
                }}
                aria-label={"search"}
                className="flex items-center"
              >
                <SearchIcon />
              </button>
            </SearchIconContainer>
          ) : null}
          {userState.isLoggedIn ? null : (
            <AccountBox
              className={`${!isSimple ? "show-desktop" : "simple-account"}`}
              mr={["5px", "5px", 20, 25]}
            >
              {isSimple ? (
                <>
                  {userState.isLoggedIn ? (
                    <Link
                      to={"/account/"}
                      onClick={() =>
                        handleNavigationSegmentEvent({
                          destination: "/account/",
                        })
                      }
                    >
                      <AccountSVG />
                    </Link>
                  ) : (
                    <Link
                      to={"/customer/account/login/"}
                      onClick={() =>
                        handleNavigationSegmentEvent({
                          destination: "/customer/account/login/",
                        })
                      }
                    >
                      <AccountSVG />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {userState.isLoggedIn ? null : (
                    <Link
                      to={"/customer/account/login/"}
                      onClick={() =>
                        handleNavigationSegmentEvent({
                          destination: "/customer/account/login/",
                        })
                      }
                    >
                      <AccountSVG />
                    </Link>
                  )}
                </>
              )}
            </AccountBox>
          )}
          {showCart && (
            <CartBox>
              <HeaderCartIcon location={location} />
            </CartBox>
          )}
          {!isSimple && (
            <BurgerBox className={classNameBurger}>
              <HamburgerOpenCloseContainer className="burger-container">
                <HamburgerOpenCloseIcon
                  id={`HamburgerOpenCloseIcon`}
                  className="toggle-nav"
                  onClick={() => {
                    closeSearchDropdown(false)
                    toggleMobileNav(!navIsOpen)
                  }}
                  aria-label="Toggle Navigation"
                  tabIndex={6}
                >
                  <CloseMenuIcon className="close-nav" />
                  <OpenMenuIcon className="open-nav" />
                </HamburgerOpenCloseIcon>
              </HamburgerOpenCloseContainer>
            </BurgerBox>
          )}
        </>
      )}
    </UserContext.Consumer>
  )
}

export default HeaderIcons
