import React from "react"
import { Box, Flex } from "@rebass/grid/emotion"
import classNames from "classnames"

import NavToggler from "../../global/header/navtoggler"
import HeaderIcons from "./headericons"
import Navigation from "./navigation"
import Logo from "./logo"

const MobileNavFlex = (props) => {
  return (
    <div
      className={classNames("flex flex-nowrap items-center sm:shadow-none", {
        "shadow-[0px_-6px_16px_0px_#0000000a]": true,
        relative: true,
      })}
    >
      <NavToggler classes={`show-md-up`} />
      <Box
        width={[70]}
        className="toggler-wrap mobile-nav-flex"
        height={props.isAccountPage ? [56] : "unset"}
      >
        <HeaderIcons
          navIsOpen={props.navIsOpen}
          showCart={false}
          isProductPage={props.isPdp}
          isAccountPage={props.isAccountPage}
          isQuizLp={props.isQuizLp}
          toggleMobileNav={props.toggleMobileNav}
        />
      </Box>
      <Box
        width={["calc(30% - 70px)", null, null, 3 / 10]}
        className="left-wrap"
      >
        <Navigation data={props.data} isMouseInHeader={props.isMouseInHeader} />
      </Box>
      <Box width={[4 / 10, null, 4 / 10]} className="center-wrap">
        <Logo
          handleNavLogoExitClick={props.handleNavLogoExitClick}
          isAccountPage={props.isAccountPage}
        />
      </Box>
      <Box width={[3 / 10, null, 3 / 10]} className="right-wrap">
        <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-end">
          <HeaderIcons
            navIsOpen={props.navIsOpen}
            showCart={props.showCart}
            classNameBurger={`show-tablet`}
            isProductPage={props.isPdp}
            isAccountPage={props.isAccountPage}
            isSimple={false}
            isQuizLp={props.isQuizLp}
            toggleMobileNav={props.toggleMobileNav}
            isIconVisibleOnRightSideOnly={props.isIconVisibleOnRightSideOnly}
            location={props.location}
          />
        </Flex>
      </Box>
    </div>
  )
}

export default MobileNavFlex
